.react-table {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 400px;
}
.react-table .thead,
.react-table .thead .tr .react-table .thead .th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}

.react-table .table {
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  display: inline-block;
}

.react-table .thead .tr.-header {
  box-shadow: 0 2px 15px 0 rgb(0 0 0 / 15%);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.react-table .thead .tr.-headerGroups {
  text-align: center;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.05); */
  background: rgba(0, 0, 0, 0.03);
}

.react-table .thead .td,
.react-table .thead .th {
  padding: 5px;
  line-height: normal;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  position: relative;
}

.react-table .td,
.react-table .th {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 7px 5px;
  overflow: hidden;
  transition: 0.3s ease;
  transition-property: padding, opacity;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.react-table .tr.-odd {
  background: #faf9f7;
}

.react-table i.failing {
  color: red;
}

.react-table i.children-failing {
  color: rgb(189, 189, 0);
}

.react-table i.success {
  color: rgb(27, 165, 0);
}

.react-table .td,
.react-table .th {
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
}

.react-table .tbody .tr:hover:not(.-children) {
  background: rgba(0, 0, 0, 0.05);
}

.react-table .tr.retried {
  font-style: italic;
}

.react-table .tr,
.nav-link {
  cursor: pointer;
}

.remove-tab:hover {
  color: red;
}

.resizer {
  display: inline-block;
  background: transparent;
  width: 10px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action: none;
}

.RequestData__body-title {
  display: flex;
  justify-content: space-between;
}
