/* TOOLBAR FILTER GROUP */
.filterButton > img {
  width: 14px !important;
  margin: 0 3px;
}
.fullScreenButton {
  margin: 0 5px !important;
}

.tableFiltersGroup {
  display: flex;
  align-items: center;
  color: #464646 !important;
  background-color: #faf9f7;
  border-radius: 30px;
  height: 48px;
  padding: 0 10px;
}

.filtersGroupAddButton {
  height: 32px;
  margin: 0;
  padding: 0 0 0 6px !important;
}

.filtersGroupDownloadProjectsButton {
  padding: 10px !important;
  background-color: #faf9f7 !important;
  border: none !important;
}

.filtersGroupDownloadProjectsButton:hover {
  background-color: #f6f4f0;
}

.globalFilterField {
  margin-right: 10px !important;
}

.globalFilterField .MuiInputBase-formControl::before {
  content: '' !important;
}
.globalFilterField .MuiInputBase-formControl .MuiInputBase-input {
  margin-left: 5px;
  font-size: 14px;
}
.globalFilterField .MuiOutlinedInput-notchedOutline {
  border: 1px solid #dfd6c4 !important;
  box-shadow: none;
}

/* ------MUI OVERRIDES-------- */

/* table body */
.MuiTableBody-root > .MuiTableRow-root:nth-child(odd) {
  background-color: #faf9f7 !important;
}

.capexTable .MuiTableRow-root {
  border: 1px solid white !important;
  height: 48px;
}

/* filter inputs */

.capexTable .MuiInputBase-input::placeholder {
  color: transparent !important;
}

.capexTable .MuiFormHelperText-root {
  color: transparent !important;
}

.capexTable .Mui-TableHeadCell-Content-Wrapper {
  font-weight: 600;
}

.capexTable .MuiInputBase-root::before {
  border-bottom: none !important;
}

.capexTable .MuiInputBase-root::after {
  display: block !important;
  visibility: visible !important;
  border-bottom: none !important;
  transform: none;
}

.capexTable .MuiInputBase-root .MuiInput-underline:focus {
  border: none !important;
}

.capexTable .MuiInputBase-root.Mui-focused {
  border-bottom: 1px solid #dfd6c4;
}

.capexTable .MuiInputAdornment-root.MuiInputAdornment-positionStart {
  position: absolute;
  left: -25px;
}

.capexTable .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
  display: none;
}

.capexTable .MuiFormControl-root {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end !important;
}

.capexTable .MuiInputBase-root {
  border-bottom: 1px solid #dfd6c4;
  width: 90%;
}
.capexTable .MuiInputBase-formControl .MuiInputBase-input {
  margin-left: 20px;
  padding: 0 0 3px 0;
  margin-top: 5px;
}

.capexTable .MuiInputBase-formControl {
  position: relative;
  font-size: 14px;
}

.capexTable .MuiInputBase-formControl::before {
  content: url('../../assets/images/icons/table/smallSearch.png');
  position: absolute;
  left: 3px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 1;
}

/* filter action buttons */

.MuiMenu-list > li:hover {
  background-color: #faf9f7 !important;
}
.MuiMenu-list {
  border: 1px solid #dfd6c4;
  border-radius: 4px;
  padding: 0 !important;
  box-shadow: 3.7px 7.4px 7.4px hsl(0deg 0% 0% / 0.39);
}
.MuiMenu-list > .Mui-selected {
  background-color: #dfd6c4 !important;
}

.capexTable .MuiButtonBase-root.Mui-active,
.capexTable .MuiButtonBase-root.MuiIconButton-root {
  opacity: 1 !important;
}
