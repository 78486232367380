.wrapperTitle {
  font-size: 18px;
  font-weight: 600;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;
  gap: 8px; /* Adds spacing between title and icon */
}

.wrapperSubtitle {
  font-size: 14px;
  margin-bottom: 20px;
  width: 100%;
}

.wrapperHeader {
  display: flex;
  align-items: center;
}
