$primary: #997b40;
$secondary: #6c757d;
$success: #779940;
$danger: #994040;
$warning: #c8a55a;
$info: #407499;
$light: #f4efe7;
$dark: #3f2d1c;

$--bs-btn-primary: 'white';
// Import Bootstrap
@import '~bootstrap/scss/bootstrap';
