body > iframe {
  display: none;
}
html,
body {
  overflow-x: hidden;
  height: 100%;
}
.bold {
  font-weight: 500;
}
.no-padding {
  padding: 0 !important;
}

body {
  font-family: 'Montserrat', sans-serif !important;
  background: rgb(238, 238, 241) !important;
}
.MuiTableContainer-root > * {
  font-family: 'Montserrat', sans-serif !important;
}

.MuiTableCell-root {
  font-family: 'Montserrat', sans-serif !important;
}

.form-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 5px;
  border-color: #dfd6c4;
}

.form-control:disabled,
.form-select:disabled {
  background-color: #faf9f7;
}

.form-control {
  border-color: #dfd6c4;
}

/*Library styles overrides*/

.form-control:focus {
  box-shadow: none !important;
}

.card {
  border-radius: 2.4px !important;
  box-shadow: rgba(27, 23, 30, 0.1) 2px 2px 3px 0px !important;
}

.table > thead > tr > th {
  font-weight: bold;
  font-size: 14px;
  color: rgb(108, 117, 125);
}

.table > tbody > tr:nth-child(odd) {
  background-color: #faf9f7;
}

.table > tbody > tr:nth-child(even) {
  background-color: white;
}

.table > tbody > tr > td {
  padding: 12px;
  font-size: 14px;
  color: rgb(108, 117, 125);
}

.pageWrapper {
  min-height: 100vh;
  display: flex !important;
}
.btn {
  color: white !important;
  border-radius: 30px !important;
  font-size: 14px !important;
}

.btn-group .btn {
  border-radius: 0 !important;
}

.btn-group .btn:first-child {
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}

.btn-group .btn:last-child {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

.icon {
  color: #464646;
}

.rmsc .dropdown-container {
  border-color: #dfd6c4 !important;
}
.rmsc .dropdown-container .dropdown-heading:focus,
.rmsc .dropdown-container .dropdown-heading:active {
  border-color: #dfd6c4 !important;
}

.rmsc {
  --rmsc-main: #dfd6c4 !important;
}

.css-13cymwt-control,
.css-12hpdv1-control {
  border-color: #dfd6c4 !important;
}

.css-t3ipsp-control:hover,
.css-t3ipsp-control:focus,
.css-t3ipsp-control:active,
.css-t3ipsp-control:focus-within,
.css-12hpdv1-control:hover,
.css-12hpdv1-control:focus,
.css-12hpdv1-control:active,
.css-12hpdv1-control:focus-within {
  border-color: #ccbda0 !important;
  box-shadow: none !important;
}

.css-1non7ou-control {
  border-color: #dfd6c4 !important;
  box-shadow: none !important;
}

.css-b62m3t-container:active {
  border-color: #ccbda0 !important;
  box-shadow: none !important;
}

.css-qr46ko > div:hover,
.css-qr46ko > div:focus-within,
.css-qr46ko > div:active,
.css-qr46ko > div:focus {
  background-color: #dfd6c4 !important;
}
.css-d7l1ni-option {
  background-color: #dfd6c4 !important;
}

.dynamicTabs {
  --bs-nav-tabs-border-color: #dfd6c4 !important;
}

.dynamicTabs .nav-link.active {
  background-color: #997b40 !important;
  color: white !important;
}

.dynamicTabs .nav-link:hover {
  border-color: #dfd6c4;
  color: #997b40;
}

hr {
  color: #ebe5da !important;
  opacity: 1;
}

.fs-5 {
  font-size: 16px !important;
}

.card-title {
  font-size: 18px !important;
}

h5 {
  font-size: 18px !important;
}

.MuiPaper-root {
  box-shadow: none !important;
  border: none;
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

.rmsc .search {
  border-bottom: 1px solid #e3ddd1 !important;
}

.feedback-button {
  height: 50px;
  width: 50px;
  background-color: #997b40;
  border-radius: 50%;
  display: inline-block;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 999;
}

.feedback-button > button:hover {
  opacity: 0.8;
}

.feedback-button > button {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}

.secondary-button {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  white-space: nowrap;
  border-radius: 30px;
  width: 100%;
  color: #997b40;
}

.subMenu {
  font-size: 14px;
  width: 95%;
}

.headerFeedbackBtn {
  background-color: white;
  color: #997b40 !important;
}
.headerFeedbackBtn:hover {
  background-color: #997b40;
  color: white !important;
}
